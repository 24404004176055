import React from 'react';
import Helmet from 'react-helmet';
import { withMixpanel } from 'gatsby-plugin-mixpanel'

import NavigationBar from '../../components/NavigationBar';
import Footer from '../../components/Footer';
import ContentHero from '../../components/ContentHero';
import MasterMindBanner from '../../components/MasterMindBanner';
import CategoryCard from '../../components/CategoryCard';
import Page from '../../layout/Page';

import styles from '../content.module.scss';
import CONTENT from '../../data/content';
import VideoOverlay from '../../components/VideoOverlay';

const content = CONTENT.contentPages.website;

const lang = 'de';
const path = '/articles/website';

class Index extends React.Component {
    constructor(props) {
        super(props);

        this.state = { showOverlay : false }
    }

    handleClose = () => {
        this.setState({showOverlay : false})

        if (document) {
            document.body.style.overflow = 'auto';
        }
    };

    handleOpen = () => {
        this.setState({showOverlay: true})

        if (document) {
            document.body.style.overflow = 'hidden';
        }
    };

    render() {
        return (
            <React.Fragment>
                <Helmet title="Perspective Resources" defer={false} />
                <Page>
                    <div className={styles.videoBackground} style={{
                        opacity: this.state.showOverlay && .7,
                        visibility: this.state.showOverlay && 'visible'
                    }} />
                    {this.state.showOverlay &&
                    <VideoOverlay url={content.heroVideoLink} onClose={this.handleClose} />
                    }
                    {/*    Navigation */}
                    <NavigationBar showBackButton={true} lang={lang} showFunnelLink={false} returnUrl={this.props.location.search} />
                    {/*    Content */}
                    {/*    Headline */}
                    {/*    Main */}
                    <div>
                        {/*ContentHero*/}
                        <ContentHero headline={content.headline[lang]}
                                     subHeadline={content.heroText[lang]}
                                     heroVideoPlaceholder={content.heroVideoPlaceholderSrc}
                                     handleClick={this.handleOpen} />
                        <div className={styles.subHeadlineContainer}>
                            <span className={styles.subHeadline}>{content.subHeadline[lang]}</span>
                        </div>
                        <div className={styles.cardsContainer}>
                            {content.cards.map(card =>
                                <CategoryCard key={card.name}
                                              name={card.name}
                                              imgSrc={card.imgSrc}
                                              title={card.title[lang]}
                                              description={card.description[lang]}
                                              linkUrl={card.url}
                                              onClick={this.handleClick}/>
                            )}
                        </div>
                        <MasterMindBanner lang={lang} />
                    </div>
                    <Footer lang={lang} path={path} />
                </Page>
            </React.Fragment>
        )
    }
}

export default withMixpanel()(Index)
